import { Hidden, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { BehaviorSubject } from "rxjs"
import { abi, address } from './abi'
import moment from "moment"
const EthJs = require("ethjs");
const EthJsContract = require("ethjs-contract");
const eth = (window as any).ethereum;
declare const web3: any;
declare const forge: any;

class Engine {
  currentAddress?: string;
  drawCanvas = document.createElement('canvas') as HTMLCanvasElement
  isMinting = false;
  e = {
    connected: new BehaviorSubject<any>(undefined),
    mintTransaction: new BehaviorSubject<string | undefined>(undefined)
  }

  constructor() {
    if (eth && eth.isMetaMask) {
      if (eth.selectedAddress) {
        this.e.connected.next(eth.selectedAddress)
      }
    }
  }

  mintGhost = async (ghostKey: string, ghostName: string) => {
    if (!this.isMinting) {
      this.isMinting = true;
      const ee = new EthJs(web3.currentProvider);
      const contract = new EthJsContract(ee)

      const abiToken = contract(abi)
      const token = abiToken.at(address)

      const txHash = await token.claimGhost(ghostKey, ghostName, { from: this.e.connected.value, value: 200000000000000000 });
      this.e.mintTransaction.next(txHash);
      let txReceipt;
      while (!txReceipt) {
        try {
          txReceipt = await ee.getTransactionReceipt(txHash)
        } catch (err) {
          console.log(err);
          throw (err)
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      const resKey = await token.getTokenIdFromGhostKey(ghostKey);
      this.isMinting = false;
      return { name: ghostName, index: resKey[0].words[0], ghostKey: ghostKey }
    }
  }

  async connectMetamask() {
    const res = await eth.request({ method: 'eth_requestAccounts' });
    if (res[0]) {
      this.e.connected.next(res[0]);
    }
  }

  getHash = (input: string) => {
    const md = forge.md.sha256.create();
    md.update(input);
    return md.digest().toHex();
  }

  generateGhost = (id: string) => {
    const WIDTH = 128
    const HEIGHT = 128
    const NBR_HASH = 150
    let final = this.getHash(id) as string;
    let tmp = final;
    for (let i = 0; i < NBR_HASH; i++) {
      tmp = this.getHash(tmp) as string;

      final += tmp;
    }
    const board = Array.from({ length: HEIGHT }).map(e => Array.from({ length: WIDTH }).map(a => {
      return {
        value: 0,
        color: [0, 0, 0],
        seen: false,
      }
    }))
    let x = Math.floor(WIDTH / 2);
    let y = Math.floor(HEIGHT / 2);
    let index = -1;
    let chienrouge = 0;
    const dir: any = { "0": 0, "1": 0, "2": 1, "3": 1, "4": 2, "5": 2, "6": 3, "7": 3, "8": 4, "9": 4, "a": 5, "b": 5, "c": 6, "d": 6, "e": 7, "f": 7, };
    for (let char of final.split("")) {
      chienrouge += 1;
      index += 1;
      const direction = dir[char];
      if (direction === 0) {
        x += 1;
      } else if (direction === 1) {
        x += -1;
      } else if (direction === 2) {
        y += 1;
      } else if (direction === 3) {
        y += -1;
      }
      else if (direction === 4) {
        x += 1;
        y += 1;
      } else if (direction === 5) {
        x += -1;
        y += 1;
      } else if (direction === 6) {
        x += 1;
        y += -1;
      } else if (direction === 7) {
        x += -1;
        y += -1;
      }
      if (
        (x >= WIDTH)
        || (y >= HEIGHT)
        || (x < 0)
        || (y < 0)
      ) {
        x = Math.floor(WIDTH / 2);
        y = Math.floor(HEIGHT / 2);
        index = 0;
      }
      board[y][x].value = 1;
      board[y][x].color[0] = index * 0.02;
      board[y][x].color[1] = index * 0.01;
      board[y][x].color[2] = index * 0.025;
    }
    return board;
  }
}

const engine = new Engine();

function GhostDisplayCanvas(p: { board: any[][] }) {
  const canvas = useRef<HTMLCanvasElement | null>(null)
  // const [background, setBackground] = useState<string>();

  useEffect(() => {
    const ctx = (canvas.current as HTMLCanvasElement).getContext("2d") as CanvasRenderingContext2D;
    ctx.fillStyle = 'white';
    ctx.clearRect(0, 0, 128, 128)
    p.board.forEach((line, y) => {
      line.forEach((point, x) => {
        if (!point.seen) {
          const color = point.color;
          ctx.fillStyle = point.value === 1 ? `rgb(${color[0]},${color[1]},${color[2]})` : "white";
          ctx.fillRect(x, y, 1, 1);
        }
      })
    });
    // setBackground(engine.drawCanvas.toDataURL())
    return () => {
      // (canvas.parentNode as any).removeChild(canvas);
    }
  })

  return <canvas className="pixel" style={{
    width: '100%',
    height: '100%',
    imageRendering: 'pixelated',
  }}
    width="128"
    height="128"
    ref={canvas}>
  </canvas>
}


function GhostDisplay(p: { board: any[][] }) {
  const div = useRef(null)
  const [background, setBackground] = useState<string>();

  useEffect(() => {
    engine.drawCanvas.width = 128
    engine.drawCanvas.height = 128
    const ctx = engine.drawCanvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.fillStyle = 'white';
    ctx.clearRect(0, 0, 128, 128)
    p.board.forEach((line, y) => {
      line.forEach((point, x) => {
        if (!point.seen) {
          const color = point.color;
          ctx.fillStyle = point.value === 1 ? `rgb(${color[0]},${color[1]},${color[2]})` : "white";
          ctx.fillRect(x, y, 1, 1);
        }
      })
    });
    setBackground(engine.drawCanvas.toDataURL())
    return () => {
      // (canvas.parentNode as any).removeChild(canvas);
    }
  }, [p.board])

  return <div className="pixel" style={{
    width: '100%',
    height: '100%',
    backgroundImage: background ? `url(${background})` : "initial",
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    imageRendering: 'pixelated',
  }}
    ref={div}>

  </div>
}

function GhostCard(p: { ghost: any }) {
  const [opa, setOpa] = useState(0);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setOpa(1);
  }, [])

  return <div style={{
    width: "310px",
    // height: "410",
    borderBottom: '1px solid #cf6eff',
    opacity: opa,
    transition: 'opacity 0.2s',
  }} onClick={() => {
    setClicked(true);
  }}>
    <div style={{
      height: "310px",
      width: "310px",
    }}>
      <GhostDisplay board={p.ghost.board}></GhostDisplay>
    </div>
    <div style={{
      textAlign: 'center',
      color: "white",
      paddingTop: "10px",
      paddingBottom: "10px",
    }}>
      {p.ghost.seed}
    </div>
  </div>
}

function MintedGhost(p: { name: string, imgUrl: string, openSeaUrl: string }) {
  return <a style={{
    display: "block",
    width: 180,
    // height: 230,
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottom: '1px solid black',
  }}
    target="_blank"
    href={p.openSeaUrl}
  >
    <div style={{
      width: 180,
      height: 180,
      backgroundImage: `url(${p.imgUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    }}>
    </div>
    <div style={{
      textAlign: "center",
      marginTop: "14px",
      fontSize: "0.8rem",
    }}>
      {p.name}
    </div>
  </a>
}

const drawings = [
  "/landing_beggar.png",
  "/landing_skull.png",
]

function App() {
  const MAX_ITEM = 500;

  const [boards, setBoards] = useState<any[]>([]);
  const [selectedBoards, setSelectedBoards] = useState<any[]>([]);
  const [rightOpenedBySize, setRightOpenedBySize] = useState(false);
  const [rightOpened, setRightOpened] = useState(false);
  const [selectedGhost, setSelectedGhost] = useState("");
  const [selectedGhostNotOk, setSelectedGhostNotOk] = useState(false);
  // const [nextButton, setNextButton] = useState(false);
  const res = useRef<{ board: any[][], seed: string }[]>([])
  const [width, setWidth] = useState(window.innerWidth);
  const [metaAdress, setMetaAdress] = useState(engine.e.connected.value);
  const [mintStatus, setMintStatus] = useState("start");
  const [hashMint, setHashMint] = useState(engine.e.mintTransaction.value);
  const [name, setName] = useState("");
  const [nameNotOk, setNameNotOk] = useState(false);
  const [catalogClicked, setCatalogClicked] = useState(false);

  const [supply, setSupply] = useState<any>(null);
  const [supplyList, setSupplyList] = useState<any[]>([]);
  const [mintedOpened, setMintedOpened] = useState(200);
  const [moreMint, setMoreMint] = useState(true);
  const [drawingIndex, setDrawingIndex] = useState(0);

  const refreshSupply = async () => {
    const res = await (await fetch(`https://cryptoghost.art/supply.json?nocache=${Date.now()}`)).json();
    console.log(res);
    setSupply(res);
    setSupplyList(Object.entries(JSON.parse(JSON.stringify(res))).sort(e => Math.random() > 0.5 ? 1 : -1))
  }

  const applySelectedGhost = (value: string) => {
    setSelectedGhost(value);
    setSelectedBoards(engine.generateGhost(value));
    setMintStatus('start')
    setName("");
  }

  const startCatalog = async () => {
    fetch("/analytic/generate").catch(() => { })
    while (true) {
      if (res.current.length < MAX_ITEM) {
        const scrollRatio = window.scrollY / (document.body.scrollHeight - window.innerHeight);
        if (document.body.scrollHeight < window.innerHeight || scrollRatio > 0.7 || res.current.length < 50) {
          for (let i = 0; i < 10; i++) {
            while (true) {
              const seed = Math.random().toString(36).substr(2, 15)
              const boa = engine.generateGhost(seed);
              let ok = false;
              for (let y = 0; y < 128; y += 4) {
                for (let x = 0; x < 128; x += 4) {
                  if (boa[y][x].color[0] > 135) {
                    ok = true;
                    break;
                  }
                }
                if (ok) break;
              }
              if (ok || Math.random() > 0.92) {
                res.current.push({ board: boa, seed });
                setBoards([...res.current]);
                break;
              }
            }
            await new Promise(resolve => setTimeout(resolve, 5));
          }
        }
      }
      await new Promise(r => setTimeout(r, 40));
    }
  }

  useEffect(() => {
    fetch("/analytic/visit").catch(() => { })
    setInterval(() => {
      setDrawingIndex((e) => {
        if (e === drawings.length - 1) {
          return 0;
        } else {
          return e + 1;
        }
      })
    }, 8000)
    let mounted = true;
    refreshSupply();
    setRightOpenedBySize(window.innerWidth > 1700);
    window.addEventListener('resize', (e) => {
      setWidth((e.target as any).innerWidth);
      setRightOpenedBySize((e.target as any).innerWidth > 1700);
    });

    engine.e.connected.subscribe((address) => {
      setMetaAdress(address);
    })

    engine.e.mintTransaction.subscribe(hash => {
      setHashMint(hash);
    })

    // ; (async () => {
    //   // let res: {board: any[][], seed: string }[] = [];
    //   ; (async () => {
    //     while (mounted) {
    //       if (res.current.length < MAX_ITEM) {
    //         const scrollRatio = window.scrollY / (document.body.scrollHeight - window.innerHeight);
    //         if (document.body.scrollHeight < window.innerHeight || scrollRatio > 0.7 || res.current.length < 50) {
    //           for (let i = 0; i < 10; i++) {
    //             const seed = Math.random().toString(36).substr(2, 15)
    //             res.current.push({board: engine.generateGhost(seed), seed, })
    //             setBoards([...res.current]);
    //             await new Promise(resolve => setTimeout(resolve, 5));
    //           }
    //         }
    //       }
    //       await new Promise(r => setTimeout(r, 40));
    //     }
    //   })()
    // })()
    return () => {
      mounted = false;
    }
  }, [])

  return <>
    <div style={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      // marginBottom: "50px",
      paddingBottom: "15px",
      paddingTop: "15px",
      // borderBottom: "1px solid #cf6eff",
    }}>
      <div style={{
        fontSize: "2rem",
        fontWeight: "bold",
        marginLeft: "5px",
      }}
        onClick={() => setRightOpened(e => !e)}
      >
        THE CRYPTO<span style={{ color: '#5a0087' }}>GHOST</span>
      </div>
      <div style={{
        display: "flex",
        // fontSize: "1em",
      }}>
        {/* <div style={{
          padding: "10px",
          cursor: "pointer",
          color: "#5a0087",
          fontWeight: "bold",
          paddingRight: "40px",
        }}
          onClick={() => {
            setRightOpened(true);
            (document.querySelector('#keyInput') as HTMLElement).focus();

          }}
        >
          ENTER GHOST KEY
        </div> */}
        {/* <div style={{
                padding: "10px",
                marginRight: "10px",
                cursor: "pointer",
              }}>
                Catalog
              </div> */}
      </div>
    </div>

    <div style={{
      width: "100%",
      height: "55vw",
      minHeight: "340px",
      backgroundImage: "url(/banner.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "grid",
      justifyContent: "center",
      alignContent: "center",
    }}>
      <div style={{
        minWidth: "480px",
        width: "40vw",
        marginLeft: "100px",
        marginRight: "100px",
        // height: "270px",
        background: "rgba(255,255,255,0.9)",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}>
          <div style={{
            width: "100px",
            height: "100px",
            backgroundImage: "url(/ghostlogo.png)",
            backgroundSize: "contain",
            marginTop: "-50px",
          }}>

          </div>
        </div>
        {supplyList && <div style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.2rem",
          paddingTop: "25px",
          paddingBottom: "10px",
        }}>
          {supplyList.length} CRYPTOGHOSTS MINTED
        </div>}


        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "25px",
        }}>
          <div style={{
            position: "absolute",
            lineHeight: "40px",
            color: "white",
          }}>
            {Math.floor((1647904072000 - Date.now()) / 1000 / 3600 / 24)} DAYS REMAINING
            {/* {{moment()("1982-5-25").countdown().toString()}} */}
            {/* { end  1647904072000 start 1616422440000 } */}
            {/* {{}} */}
          </div>

          <div style={{
            width: "80%",
            height: "40px",
            backgroundColor: "#ca8efa",
          }}>
            <div style={{
              width: `${(365 - Math.floor((1647904072000 - Date.now()) / 1000 / 3600 / 24)) / 365 * 100}%`,
              height: "100%",
              backgroundColor: "#4c0092",
            }}>
            </div>
          </div>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "35px",
        }}>
          <a style={{
            display: "block",
            backgroundImage: "url(/twitter_little.png)",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "40px",
            height: "30px",
          }}
            href="https://twitter.com/CryptoghostA"
            target="_blank"
            rel="noreferrer"
          >
          </a>
          <a style={{
            display: "block",
            backgroundImage: "url(/discord_little.png)",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "40px",
            height: "30px",
            marginLeft: "10px",
          }}
            href="https://discord.gg/NUcVR9w3WD"
            target="_blank"
            rel="noreferrer"
          >
          </a>

        </div>
      </div>
    </div>
    <div style={{
      background: "#21034a",
      paddingTop: "200px",
      paddingBottom: "200px",
    }}>
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}>
        <div style={{
          width: "500px",
          height: "300px",
          backgroundImage: `url(${drawings[drawingIndex]})`,
          transition: "background 1s",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundColor: "#FBFBFB",
        }}>
        </div>
        <div style={{
          width: "500px",
          color: "white",
          fontSize: "1.2rem",
          padding: "20px",
          paddingLeft: width < 400 ? "20px" : "40px",
        }}>

          Cryptoghost is a generative art experiment using encrypted data from the ethereum blockchain to generate these purple pixelated fractals using a <span style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#d2a2ff",
          }}
            onClick={() => {
              setRightOpened(true);
              (document.querySelector('#keyInput') as HTMLElement).focus();
            }}
          >ghost-key</span>.<br /><br />
          Sometimes, like when you look in the clouds, you see things taking shape, sometimes they're obvious, sometimes they're just an expression of your subconscious.<br /><br />
          if you find one that everyone agrees on, no doubt it's a rare one !
        </div>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        color: "white",
        marginTop: "140px",
      }}>
        <div style={{
          width: "800px",
        }}>
          <div style={{
            fontWeight: "bold",
            fontSize: "1.4rem",
            textAlign: "center",
            marginBottom: "27px",
          }}>
            SUPERCHARGED ERC-721
          </div>
          <div style={{
            padding: "20px",
            fontSize: "1.2rem",
          }}>
            The possibilities of different Cryptoghosts are almost infinite, once you find the one you like, you can mint it and choose a name.<br />
            The name is useful to make the viewer understand what it represents.<br /><br />
            Cryptoghost is a fully on-chain NFT, both for metadata and images.<br />
            In fact, this is the first NFT that generates bitmap files directly from the smartcontract, <a
              target="_blank"
              href="https://github.com/dezmou/Cryptoghost.art"
              rel="noreferrer"
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "#d2a2ff",
                textDecoration: 'none',
              }}
            >learn more.</a><br />
            Unlike most NFT projects, as long as ethereum exists, then Cryptoghosts exists.<br />
          </div>
        </div>
      </div>
    </div>

    <div style={{
      paddingTop: "100px",
      paddingBottom: "50px",
      textAlign: "center",
      fontSize: "1.6rem",
      fontWeight: "bold",
      color: "#21034a",
      // width: "100%",
    }}>
      MINTED COLLECTION
    </div>
    {supply && <div>
      <div style={{
        display: "flex",
        justifyContent: "center",
      }}>
        <div style={{
          maxWidth: "1080px",
          width: "100%",
          // fontFamily: "impact",
        }}>

          {/* <div style={{
            width: "100%",
            height: "20vh",
            background: "url(example.png)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}>
          </div> */}
          <br />
          <br />

          <div id="mintedContainer" style={{
            width: "95%",
            minWidth: "380px",
            height: width < 400 ? `${mintedOpened}px` : 'initial',
            overflow: "hidden",
            // border: "1px solid red",
            transition: "height 0.3s",
          }}>
            <div id="mintedContainerElements" style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, 180px)",
              gridTemplateRows: "repeat(auto-fill, 180px)",
              justifyContent: "space-around",
              gridGap: "20px",
            }}>
              {supplyList.map((e: any[]) => <div key={e[0]} className="mintedItem" style={{
                width: "100%",
                height: "180px",
                // boxShadow: '0px 0px 2px 0px #000000',
                borderRadius: "10px 10px 0px 0px",
                cursor: "pointer",
                background: "white",
              }}
                onClick={() => {
                  setRightOpened(true);
                  applySelectedGhost(e[0]);
                }}
              >
                <div style={{
                  height: "75%",
                  width: "100%",
                  backgroundImage: `url(https://cryptoghost.art/meta/images/${e[1].index}.jpg)`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}>
                </div>
                <div style={{
                  height: "25%",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  background: "#5a0087",
                  display: "table",
                  width: "100%",
                  color: "white",
                }}>
                  <div style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}>
                    {e[1].name}
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
          {(moreMint && width < 400) && <>
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
              onClick={() => {
                const totalHeight = document.querySelector("#mintedContainer")!.clientHeight;
                const childHeight = document.querySelector("#mintedContainerElements")!.clientHeight;
                if (totalHeight < childHeight) {
                  setMintedOpened((a) => {
                    let newA = a + (180 + 20) * 2;
                    if (newA > childHeight) {
                      setMoreMint(false);
                      newA = childHeight + 10;
                    };
                    return newA;
                  });
                }
              }}
            >
              <div style={{
                width: 40,
                height: 40,
                backgroundImage: "url(/expand.svg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                cursor: "pointer",
              }}>
              </div>
            </div>
          </>}

        </div>
      </div>
      <div style={{
        width: "100%",
        backgroundColor: "#21034a",
        marginTop: "100px",
      }}>
        <div style={{
          paddingTop: "100px",
          paddingBottom: "50px",
          textAlign: "center",
          fontSize: "1.6rem",
          fontWeight: "bold",
          color: "white",
          // width: "100%",
        }}>
          MINT YOUR OWN
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
        }}>
          <div style={{
            width: "800px",
            padding: "20px",
            color: "white",
            fontSize: "1.2rem",
          }}>
            Time to sink into the infinite scroll, don't forget to take breaks and drink water regularly. <br /><br />

            Once you've found the perfect Cryptoghost, first you give it a name.<br />
            Several Cryptoghost can have the same name, but none can share the same ghost-key.<br />
            Then minting is 0.2 ETH + fees.<br /><br />

            Given the difficulty to find a rare Cryptoghost, the minting period is open for 1 year, without supply limit.<br />
            Once March 22, 2022 is reached, the minting will not be possible anymore, the collection will be complete.<br />
          </div>
        </div>

        <div id="catalog1" style={{
          // textAlign: "center",
        }}>
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
            {!catalogClicked && <div style={{
              marginTop: "10px",
              border: "2px solid #cf6eff",
              background: "#fff7ff",
              borderRadius: "5px",
              width: width < 500 ? "70%" : "30%",
              height: "40px",
              lineHeight: "40px",
              textAlign: "center",
              cursor: "pointer",
              marginBottom: "40px",

            }} onClick={async () => {
              setCatalogClicked(true);
              startCatalog();
            }}>
              Invoke random ghosts
            </div>}
          </div>
        </div>

        <div id="catalog" style={{
          display: "flex",
          justifyContent: "center",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
          }}>
            <div style={{
              maxWidth: 1080,
              minHeight: catalogClicked ? "100vh" : "initial",
            }}>
              <div style={{
                display: "flex",
                flexWrap: 'wrap',
                justifyContent: "center",
              }}>
                {boards.map((board, i) => {
                  return <div key={board.seed} style={{
                    margin: '10px',
                    cursor: "pointer",
                  }}
                    onClick={() => {
                      setRightOpened(true);
                      applySelectedGhost(board.seed)
                    }}
                  >
                    <GhostCard ghost={board}></GhostCard>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>

        {(boards.length >= MAX_ITEM) && <div style={{
          display: "flex",
          justifyContent: "center",
          width: "1005",
        }}>
          <div style={{
            marginTop: "10px",
            border: "2px solid #cf6eff",
            background: "#fff7ff",
            borderRadius: "5px",
            width: "20%",
            height: "40px",
            lineHeight: "40px",
            textAlign: "center",
            cursor: "pointer",
            marginBottom: "40px",
          }} onClick={async () => {
            (document as any).getElementById('catalog').scrollIntoView();
            res.current = [];
          }}>
            New Page
          </div>
        </div>
        }

      </div>



      <div style={{
        position: "fixed",
        maxWidth: "360px",
        width: "100vw",
        minWidth: "360px",
        height: "100vh",
        background: "white",
        top: 0,
        left: width,
        // marginLeft: rightOpenedBySize ? (-(width <= 360 ? width : 360)) : (rightOpened ? (-(width <= 360 ? width : 360)) : 0),
        marginLeft: false ? (-(width <= 360 ? width : 360)) : (rightOpened ? (-(width <= 360 ? width : 360)) : 0),
        transition: 'margin 0.15s',
        // display: "flex",
        // justifyContent: "center",
        borderLeft: false ? "none" : "1px solid #cf6eff",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
      >
        {true && <div style={{
          cursor: "pointer",
        }}
          onClick={() => {
            setRightOpened(false);
          }}
        >
          <CloseIcon style={{
            color: "#cf6eff",
            fontSize: "2rem",
            padding: "1%",
          }}></CloseIcon>
        </div>
        }
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}>
          <div style={{
            width: "95%",
          }}>

            <TextField id="keyInput" label="Ghost key" variant="outlined" style={{
              width: "100%",
              marginTop: 15,
              marginBottom: 15,
            }} value={selectedGhost} onChange={(e) => {
              const alloweds = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789'.split("")
              let ok = true;
              for (let char of e.target.value.split("")) {
                if (alloweds.indexOf(char) === -1) {
                  ok = false;
                  break;
                }
              }
              if (e.target.value.length > 25) {
                ok = false;
              }
              if (ok) {
                applySelectedGhost(e.target.value);
                setSelectedGhostNotOk(false);
              } else {
                setSelectedGhostNotOk(true);
              }
            }} />
            {selectedGhostNotOk && <>
              <div style={{
                textAlign: "center",
                color: "#881010",
                marginBottom: "8px",
              }}>
                Max length 25 and only alpha-num characters
              </div>
            </>}
            {selectedGhost && <><div style={{
              width: "100%",
              height: "100%",
              maxWidth: "340px",
              maxHeight: "340px",
              marginBottom: "8px",
              border: "1px solid #dadada",
            }}>
              <GhostDisplayCanvas board={selectedBoards}
              ></GhostDisplayCanvas>
            </div>

              {supply[selectedGhost] && <div>
                <div style={{
                  textAlign: "center",
                  fontSize: "1.7rem",
                  fontWeight: "bold",
                  color: "#cf6eff",
                  marginBottom: "20px",
                  marginTop: "15px",
                }}>
                  {supply[selectedGhost].name}
                </div>
                <div style={{
                  textAlign: "center",
                }}>
                  <a href={`https://opensea.io/assets/0x6f6dba0170de97249083994d85204f507cf416b4/${supply[selectedGhost].index}`} target="_blank" >Trade on OpenSea</a>
                </div>

              </div>}
              {!supply[selectedGhost] && <>
                <div style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "#008800",
                  marginBottom: "15px",
                }}>
                  AVAILABLE
                </div>
                {(metaAdress && mintStatus === "start") && <div>
                  <TextField id="outlined-basic" label="Give your ghost a name" variant="outlined" style={{
                    marginTop: "8px",
                    width: "100%",
                  }}
                    value={name}
                    onChange={(e) => {
                      const alloweds = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789 '.split("")
                      let ok = true;
                      for (let char of e.target.value.split("")) {
                        if (alloweds.indexOf(char) === -1) {
                          ok = false;
                          break;
                        }
                      }
                      if (e.target.value.length > 30) {
                        ok = false;
                      }
                      if (ok) {
                        setName(e.target.value);
                        setNameNotOk(false);
                      } else {
                        setNameNotOk(true);
                      }
                    }} />
                  {nameNotOk && <>
                    <div style={{
                      textAlign: "center",
                      color: "#881010",
                      marginBottom: "8px",
                    }}>
                      Max length 30 and only alpha-num characters and space
                    </div>
                  </>}

                  <div>
                    {name.length > 0 && <>
                      <div style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}>
                        Once minted, you will never be able to change ghost's name, choose wisely
                      </div>
                      <div style={{
                        marginTop: "10px",
                        border: "2px solid #cf6eff",
                        background: "#fff7ff",
                        borderRadius: "5px",
                        width: "100%",
                        height: "40px",
                        lineHeight: "40px",
                        textAlign: "center",
                        cursor: "pointer",
                      }} onClick={async () => {
                        try {
                          setMintStatus("minting");
                          const res = await engine.mintGhost(selectedGhost, name);
                          if (res) {
                            setSupply({ ...supply, [res.ghostKey]: { name: res.name, index: res.index } });
                          }
                          setMintStatus("success");
                        } catch (error) {
                          setMintStatus("start");
                        }
                      }}>
                        Mint Ghost for 0.2 ETH
                      </div>
                    </>}
                  </div>
                </div>}
                {mintStatus === "minting" && <div>
                  <div style={{
                    textAlign: "center",
                  }}>
                    Sending transaction...
                  </div>
                  <div style={{
                    width: "100%",
                    height: "80px",
                    background: `url(./loading.svg)`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}>

                  </div>
                  <div style={{
                    textAlign: "center",
                  }}>
                    <a target="_blank" href={`https://etherscan.io/tx/${hashMint}`}>
                      View on etherscan
                    </a>
                  </div>

                </div>}

                {mintStatus === "success" && <div>
                  <div style={{
                    textAlign: "center",
                  }}>
                    Succes minted ghost <br />
                  </div>

                </div>}
                {(!metaAdress && eth) && <div onClick={() => {
                  engine.connectMetamask()
                }}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                    border: "1px solid #dadada",
                    borderRadius: "5px",
                    height: "50px",
                  }}
                >
                  <div style={{
                    width: "20%",
                    height: "100%",
                    backgroundImage: "url(./metamask.svg)",
                    backgroundSize: "50%",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}>

                  </div>
                  <div style={{
                    textAlign: "center",
                    width: "80%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}>
                    Mint with Metamask
                  </div>
                </div>}

                {!eth && <div style={{
                  textAlign: "center",
                }}>
                  Install Metamask to mint Ghost
                </div>}
              </>}

              {!supply[selectedGhost] && <div style={{
                width: 'calc(100% - 28px)',
                padding: '16px',
                border: '1px solid #d0d0d0',
                borderRadius: '5px',
                marginTop: "40px",
                paddingTop: "20px",
              }}>
                <div style={{
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  backgroundImage: "url(/light.png)",
                  backgroundSize: 'contain',
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  marginTop: "-40px",
                  marginLeft: "calc(50% - 40px)",
                }}>
                </div>
                <div style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}>
                  Found a rare CryptoGhost but don't have enough ethereum to mint it?<br /><br />
                  Download its image and share it on <a
                    href="https://discord.gg/NUcVR9w3WD"
                    target="_blank"
                    style={{
                      color: "#5a0087",
                    }}>
                    the discord
                  </a>. <br /><br />
                  Maybe someone will want to buy you the associated ghost-key.

                </div>
              </div>
              }

            </>}

          </div>
        </div>
      </div>
    </div>

    }

  </>
}

export default App;

// Dog goat house elephant sheep queen zebra crocodile float kite flounder money

/**

Il y a des milliard d'année, la blockchain ethereum est née.
Uniquement conceptuellement par l'univers, il n'a été découvert qu'en 2015.
Grâce à l'informatique, nous somme désormais capable d'interagir avec elle.

Chaque message crypté existe deja parmis un nombre de possibilité environ egal au nombre d'atomes dans l'univers

Nous avons utilisé l'ethereum pour donner vie au ames de ces données errante avec leur representations fractals pour leur rendre hommage au mieux.




Billions of years ago, the ethereum blockchain was born.
Only conceptually by the universe, it was only discovered in 2015.
Thanks to computers, we are now able to interact with it.

Each encrypted message already exists among a number of possibilities approximately equal to the number of atoms in the universe

We have used ethereum to bring to life the souls of these wandering data with their fractal representations to honour them in the best possible way.


 */